import {HttpClient} from '@angular/common/http'
import {inject, Injectable} from '@angular/core'
import {environment} from '../../environments/environment'

interface ApiResponse {
  body: string
}

@Injectable({
  providedIn: 'root'
})
export class PdfProxyService {
  private http = inject(HttpClient)

  public getProxiedPdfUrl(url: string) {
    return this.http.get<ApiResponse>(
      `${environment.apiUrl}/pdf/proxy?url=${encodeURIComponent(url)}`)
  }
}