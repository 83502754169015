<!-- Header - Only available when non running inside a frame -->
@if (!configService.onInternetBankRoute) {
  <spb-header></spb-header>
}

<!-- Displays a PDF document within the application -->
@if (configService.onMobileBankContext) {
  <spb-pdf-viewer
  ></spb-pdf-viewer>
}

<!-- Normal content -->
<router-outlet></router-outlet>

<!-- Footer - Only available when non running inside a frame -->
@if (!configService.onInternetBankRoute) {
  <spb-footer
    (logoutUser)="configService.logout()"
    [license]="true"
    [token]="(configService.accessToken$ | async)"
    [version]="version"
    [copyrightYears]="copyrightYears"
  ></spb-footer>
}